/* eslint-disable indent */
import React from 'react';
import * as d3 from 'd3';

import { IData } from '../../utils/globalTypes';

const XAxis = ({ data, currentTime, width, height, yScale, divideByTwo }: IAxisProps) => {
	const ref = React.useRef<any>(null);

	const scale = d3
		.scaleTime()
		.domain([new Date(2020, 0, 1, 7, 0, 0), new Date(2020, 0, 1, 23, 0, 0)])
		.range([0, width]);

	const xAxis = d3.axisBottom(scale);

	// @ts-ignore
	xAxis.ticks().tickFormat(d3.timeFormat('%H:00'));

	//const xAxisGroup = d3.select(ref.current)
	//.attr('transform', `translate(0, ${y0Position})`)
	//.call(xAxis);

	const xAxisGroup = d3
		.select(ref.current)
		.attr('transform', `translate(0,${divideByTwo ? height / 2 : height})`)
		// .attr('transform', `translate(0, ${yScale(0)})`)
		.call(xAxis);

	const ticks = xAxisGroup.selectAll('.tick');
	//.filter(function(d, i) { return i > 0; });

	// @ts-ignore
	ticks.each(function (d: Date, i) {
		const tick = d3.select(this);
		const hours = d.getHours();
		const minutes = d.getMinutes();
		const time = `${hours.toString().length > 1 ? hours : '0' + hours}:${
			minutes.toString().length > 1 ? minutes : '0' + minutes
		}`;

		// Remove first and last tick
		if (i === 0 || i === 1 || i === ticks.size() - 1) {
			tick.remove();
			return;
		}

		// reset
		tick.selectAll('circle').remove();
		tick.selectAll('image').remove();
		tick.selectAll('line').remove();

		// hide text on uneven or current time
		tick.selectAll('text').style('opacity', i % 2 === 0 ? 0 : 1);

		// remove first and last item
		if (i !== 0 && i < ticks.size() - 1) {
			tick.append('circle').attr('r', '.4%').style('fill', 'white').style('opacity', 1).raise();

			if (time !== currentTime) {
				tick.append('svg:image')
					.attr('xlink:href', `assets/${data.items[i * 4].value}.svg`)
					.attr('x', '-.6%')
					.attr('y', width * 0.01)
					.attr('width', '1.2%')
					.raise();

				tick.select('text')
					.style('fill', null) // Reset the fill color to default (null or empty)
					.style('font-size', null) // Reset the font size to default (null or empty)
					.style('font-family', null); // Reset the font family to default (null or empty)
			}

			if (time === currentTime) {
				// tick.append('line')
				// 	.attr('x', 0) // Starting x-coordinate
				// 	.attr('y1', -width * 0.012) // Starting y-coordinate
				// 	.style('stroke', 'white') // Line color
				// 	.style('stroke-width', width * 0.003) // Line width
				// 	.raise();

				tick.append('svg:image')
					.attr('xlink:href', `assets/${data.items[i * 4].value}_white.svg`)
					.attr('x', '-.6%')
					.attr('y', width * 0.01)
					.attr('width', '1.2%')
					.raise();

				tick.select('text')
					.style('fill', 'white')
					.style('font-size', width * 0.0125)
					.style('font-family', 'FigTree')
					.style('opacity', 1);
			}
		}
	});

	xAxisGroup
		.selectAll('text')
		.attr('color', '#727272')
		.attr('font-size', width * 0.009)
		.attr('transform', `translate(0,${height + height * 0.35})`);

	xAxisGroup
		.select('.domain')
		.attr('stroke', 'url(#xAxisGradient-weather)')
		.attr('stroke-width', '.3%')
		.attr('opacity', '.4');

	return <g ref={ref} className="weather-axis"></g>;
};

interface IAxisProps {
	data: IData;
	currentTime: string;
	width: number;
	height: number;
	yScale: d3.ScaleLinear<number, number, never>;
	divideByTwo?: boolean;
}

export default XAxis;
