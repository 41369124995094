import React from 'react';

import './App.css';
import Dashboard from './Dashboard';
import ConfigProvider from './providers/ConfigProvider';

function App() {
	const groupId =
		window.location.pathname.split('/')[1] || new URLSearchParams(window.location.search).get('group') || '';

	return (
		<ConfigProvider>
			<>
				<Dashboard group={groupId} />
			</>
		</ConfigProvider>
	);
}

export default App;
