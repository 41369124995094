import React from 'react';
import * as d3 from 'd3';

import {
	StackedArea,
	StackedAreaGradient,
	CurrentSituationLineGradient,
	CurrentSituationLine,
	CurrentSituationBackground,
	Fade,
	Line,
	Border,
} from '../../components';
import { BORDER_TYPE, DATA_TYPE, IData } from '../../utils/globalTypes';
import useDimensions from '../../utils/useDimensions';
import { Y_MIN_VALUE, Y_MAX_VALUE } from '../../constants';

import { renderAxis } from './Axis';
import useController from './MultilineChart.controller';
import { MultilineChartProps } from './MultilineChart.types';

const MultilineChart = ({ data = [], margin = {}, progress, maxHeight, opacity, yScale }: MultilineChartProps) => {
	const svgRef = React.useRef<any>(null);
	const [containerRef, { svgWidth, svgHeight, width, height }] = useDimensions({
		maxHeight: maxHeight,
		margin,
	});

	const padding = 25;
	const typesToStack = ['car', 'load', 'dc', 'battery'];
	const stackedData = data.filter((d) => d && typesToStack.includes(d.type));

	const priceData = data.find((d) => d && d.type === 'price') || null;

	const controller = useController({
		data: [{ items: [{ value: -70 }, { value: 70 }] }],
		width: width,
		height: svgHeight,
	});

	const priceController = useController({
		data: priceData ? [priceData] : null,
		width: width,
		height: svgHeight / 2,
	});

	//const { yScale } = controller;
	const { yScale: priceYScale } = priceController;

	const getSplittedData = (data: IData, progress: number, type: DATA_TYPE): IData => {
		const pastAmountOfData = Math.round(data.items.length * (progress / 100));

		return {
			...data,
			items:
				type === DATA_TYPE.past
					? data.items.slice(0, pastAmountOfData)
					: data.items.slice(pastAmountOfData > 0 ? pastAmountOfData - 1 : 0),
		};
	};

	const getSplittedDataArray = (data: IData[], progress: number, type: DATA_TYPE): IData[] => {
		return data.map((d) => getSplittedData(d, progress, type));
	};

	return (
		<div className="chartContainer" ref={containerRef} style={{ height: height, opacity: opacity }}>
			<svg width={svgWidth} height={height} ref={svgRef}>
				<g>
					{stackedData && (
						<>
							{renderAxis({ svgRef, width: svgWidth, height: svgHeight })}
							{progress > 0 && yScale && (
								<StackedArea
									key={`area-${DATA_TYPE.past}`}
									data={getSplittedDataArray(stackedData, progress, DATA_TYPE.past)}
									yScale={yScale}
									isSmooth={false}
									start={0}
									progress={progress}
									width={svgWidth}
									type={DATA_TYPE.past}
								/>
							)}
							{progress < 100 && yScale && (
								<StackedArea
									key={`area-${DATA_TYPE.future}`}
									data={getSplittedDataArray(stackedData, progress, DATA_TYPE.future)}
									yScale={yScale}
									isSmooth={true}
									start={progress}
									progress={progress}
									width={svgWidth}
									type={DATA_TYPE.future}
								/>
							)}
						</>
					)}
					{priceData && priceYScale && (
						<>
							{progress > 0 && (
								<Line
									id={`${priceData.type}-${DATA_TYPE.past}`}
									key={`${priceData.type}-${DATA_TYPE.past}`}
									data={getSplittedData(priceData, progress, DATA_TYPE.past).items}
									type={DATA_TYPE.past}
									yScale={priceYScale}
									color={priceData.stroke}
									isSmooth={false}
									dashed={true}
									start={0}
									progress={progress}
									width={svgWidth}
									legend="grid"
									logo="grid"
								/>
							)}
							{progress < 100 && (
								<Line
									id={`${priceData.type}-${DATA_TYPE.future}`}
									key={`${priceData.type}-${DATA_TYPE.future}`}
									data={getSplittedData(priceData, progress, DATA_TYPE.future).items}
									type={DATA_TYPE.future}
									yScale={priceYScale}
									color={priceData.stroke}
									isSmooth={true}
									dashed={true}
									start={progress}
									progress={progress}
									width={svgWidth}
								/>
							)}
						</>
					)}
					<CurrentSituationLine
						width={svgWidth}
						height={height}
						padding={padding}
						progress={progress}
						type={'stackedArea'}
					/>
					<CurrentSituationLineGradient color={'white'} type={'stackedArea'} />
					<CurrentSituationBackground
						width={svgWidth}
						height={height}
						progress={progress}
						type={'stackedArea'}
					/>
					{stackedData.map((d, index) => (
						<StackedAreaGradient
							key={`${d.filename}-${index}`}
							color={d.fill || '#00999F'}
							type={d.filename}
						/>
					))}
					<StackedAreaGradient color={'#00999F'} type="dc" />
					<StackedAreaGradient color={'#AFAFAF'} type="load" />
					<Fade width={svgWidth} height={svgHeight} />
					<Border
						width={svgWidth}
						height={height * 0.78}
						type={BORDER_TYPE.bottom}
						color={'url(#xAxisGradient-capacity)'}
					/>
					{/* <Border width={svgWidth} height={height} type={BORDER_TYPE.left} />
					<Border width={svgWidth} height={height} type={BORDER_TYPE.right} /> */}
				</g>
			</svg>
		</div>
	);
};

MultilineChart.defaultProps = {
	data: [],
	margin: {
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	},
};

export default MultilineChart;
